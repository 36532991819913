import {Button} from "./Button";
import {router} from "next/router";

export default function AnnouncementArea({heading, kickerbutton, paragraph, buttontext, href}) {
    return (
        <div
            className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-md border border-gray-50 transition-all sm:w-full sm:p-6 sm:mb-10">
            <div className="sm:flex sm:items-start">

                <div className="text-left mt-3 sm:mt-0 sm:ml-0 sm:text-left">
                    <div className={"flex align-center "}>
                        <h2 className="text-xl font-semibold leading-6 text-gray-900 mt-2 ">
                            {heading}
                        </h2>
                        <div>
                            <span
                                className="inline-flex items-center rounded-md bg-green-100 px-2.5 py-0.5 text-sm font-medium text-green-800 ml-3 mt-2">
                                 {kickerbutton}
                        </span>
                        </div>
                    </div>


                    <div className={"-mt-3"}>
                        <p className="text-gray-500">
                            {paragraph}
                        </p>
                    </div>
                </div>
            </div>
            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <Button text={buttontext} onClick={() => router.push(href)}/>
            </div>
        </div>
    )
}
