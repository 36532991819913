import {useEffect, useState} from 'react'
import DefaultLayout from "../components/DefaultLayout";
import Layout from "../components/Layout";
import Image from "next/image";
import {useRouter} from 'next/router'
import {
    BanknotesIcon,
    CheckBadgeIcon,
    UsersIcon
} from "@heroicons/react/24/outline";
import {Notification} from "../components/Notification";
import {useAuth} from "../context/AuthContext";
import AnnouncementArea from "../components/AnnouncementArea";
import {serverSideTranslations} from "next-i18next/serverSideTranslations";
import {useTranslation} from "next-i18next";
import nextApi from "../util/apiConfig";
import Link from "next/link";
import LanguageSwitchDesktop from "../components/LanguageSwitchDesktop";
import DefaultHead from "../components/DefaultHead";
import globalmeta from "../globalmeta";


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Home(props) {
    const router = useRouter();
    let {t} = useTranslation('index')
    const [info, setInfo] = useState("");
    const [announcement, setAnnouncement] = useState({});
    const user = useAuth().user;
    let locale = props.locale

    const announcement_noplan = {
        de: {
            heading: "Dein Account ist gerade nicht aktiv",
            kicker: "Wechsle jetzt auf einen bezahlten Plan",
            paragraph: "Wähle einen unserer premium Pläne ab 9€/Monat und erstelle großartige Texte.",
            buttonText: "Pläne anschauen",
            href: "/plans"
        },
        en: {
            heading: "Your account is currently not active",
            kicker: "Start a paid plan now",
            paragraph: "Select one of our paid plans starting at 9€ to generate amazing copy.",
            buttonText: "View plans",
            href: "/plans"
        }
    }

    const announcement_testphase = {
        de: {
            heading: "Dein Testzeitraum hat begonnen",
            kicker: "Erstelle jetzt deinen ersten Text",
            paragraph: "Beginne damit, ReScribe ein bisschen kennenzulernen. Am besten schreibst du direkt deinen ersten Blog Artikel und bekommst ein Gefühl dafür, was in Zukunft alles möglich sein wird!",
            buttonText: "Jetzt loslegen",
            href: "/#templates"
        },
        en: {
            heading: "Your trial account has started",
            kicker: "Start creating now",
            paragraph: "Begin by getting to know ReScribe and create your first AI-assisted piece of copy. Blog articles are a great way to test the capabilities of ReScribe.",
            buttonText: "Start Creating",
            href: "/#templates"
        }
    }

    useEffect(() => {
        let {message} = router.query;
        switch (message) {
            case "plan-update":
                setInfo("Plan successfully updated");
                break;
            case "plan-cancel":
                setInfo("Plan successfully cancelled");
                break;
            case "plan-create":
                setInfo("Plan successfully activated.");
                break;
            default:
                break;
        }

    }, [router.query]);


    useEffect(() => {
        //redirect to plans if it's the first login
        if (user.firstLogin && !(user.subscriptionStatus === "active") || user.subscriptionStatus==="inactive") router.push("/plans")
        //setting announcement content
        if (user._id) {
            nextApi.get("/users?id=" + user._id)
                .then(res => {
                    let {subscriptionStatus} = res.data.message;
                    if (subscriptionStatus !== "active") {
                        setAnnouncement(locale === "de" ? announcement_noplan.de : announcement_noplan.en)
                    } else {
                        setAnnouncement(locale === "de" ? announcement_testphase.de : announcement_testphase.en)
                    }

                })
        }
    }, [router]);


    const actions = [
        {
            title: t('section-templates-action-1-title'),
            text: t('section-templates-action-1-text'),
            href: '/create-ad',
            icon: BanknotesIcon,
            iconForeground: 'text-rescribe',
            iconBackground: 'bg-gray-50',
            image: "/chart.png"
        },
        {
            title: t('section-templates-action-2-title'),
            text: t('section-templates-action-2-text'),
            href: '/create-website-text',
            icon: CheckBadgeIcon,
            iconForeground: 'text-rescribe',
            iconBackground: 'bg-gray-50',
            image: "/macbook.png"
        },
        {
            title: t('section-templates-action-3-title'),
            text: t('section-templates-action-3-text'),
            href: '/ecommerce',
            icon: UsersIcon,
            iconForeground: 'text-rescribe',
            iconBackground: 'bg-gray-50',
            image: "/keyboard.png"
        },
        {
            title: t('section-templates-action-4-title'),
            text: t('section-templates-action-4-text'),
            href: '/blogpost-wizard',
            icon: BanknotesIcon,
            iconForeground: 'text-rescribe',
            iconBackground: 'bg-gray-50',
            image: "/book.png"
        },
        {
            title: t('section-templates-action-5-title'),
            text: t('section-templates-action-5-text'),
            href: '/rephrase',
            icon: BanknotesIcon,
            iconForeground: 'text-rescribe',
            iconBackground: 'bg-gray-50',
            image: "/rephrase.png"
        },
        {
            title: t('section-templates-action-6-title'),
            text: t('section-templates-action-6-text'),
            href: '/socialmedia',
            icon: BanknotesIcon,
            iconForeground: 'text-rescribe',
            iconBackground: 'bg-gray-50',
            image: "/phone.png"
        },

    ]

    return (
        <>
            <Notification show={!!info} setShow={(x) => !x && setInfo("")} message={info}/>
            <div
                className="flex flex-col  box-border py-12 lg:px-10 items-center justify-center relative">
                <LanguageSwitchDesktop/>
                <div id="content-container" className="mx-auto w-full max-w-7xl flex flex-col px-4 justify-center">
                    <div className="flex">
                        <span className="text-2xl font-bold text-gray-900 sm:text-4xl mb-2 inline pr-16">
                            {t('hero-heading', {user})}
                            <Image src={"/bold.png"} width={40} height={0} className={"inline mb-1 ml-1"} alt={"Rocket"}/>
                        </span>


                    </div>
                    <p className={"mb-8 text-gray-500"}>{t('subheading')}</p>
                    {announcement && <AnnouncementArea heading={announcement.heading}
                                                       kickerbutton={announcement.kicker}
                                                       paragraph={announcement.paragraph}
                                                       buttontext={announcement.buttonText}
                                                       href={announcement.href}/>}
                    <div className="min-w-0 flex-1">
                        <h2 className="font-medium leading-7 text-gray-900 sm:truncate sm:text-2xl sm:tracking-tight mt-10 md:mt-0"
                            id="templates">
                            {t('section-templates-heading')}
                        </h2>
                    </div>
                    <div
                        className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-gray-200 shadow-md border border-gray-50 sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0 ">
                        {actions.map((action) => (
                            <div
                                key={action.title}
                                className={'relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500'}
                            >
                                <div>
            <span
                className={classNames(
                    action.iconBackground,
                    action.iconForeground,
                    'rounded-lg inline-flex p-3 ring-4 ring-white'
                )}
            >
              {<Image
                  onClick={() => router.push("/")}
                  width={30}
                  height={30}
                  fill={false}
                  src={action.image}
                  alt={action.image}
              />}

            </span>
                                </div>
                                <div className="mt-8">
                                    <h3 className="text-lg font-medium">
                                        <Link href={action.href} className="focus:outline-none">
                                            {/* Extend touch target to entire panel */}
                                            <span className="absolute inset-0" aria-hidden="true"/>
                                            {action.title}
                                        </Link>
                                    </h3>
                                    <p className="mt-2 text-sm text-gray-500">
                                        {action.text}
                                    </p>
                                </div>
                                <span
                                    className="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400"
                                    aria-hidden="true"
                                >
            <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
              <path
                  d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z"/>
            </svg>
          </span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}


export async function getStaticProps({locale}) {
    return {
        props: {
            locale: locale,
            ...(await serverSideTranslations(locale, ['index']))
        },
    };
}

Home.requireAuth = true;

Home.getLayout = function getLayout(page) {
    let {locale} = page.props;

    const structuredLd = JSON.stringify({
        "@context": globalmeta.siteUrl + "/" + locale,
        "description": "ReScribe - Schreibe bessere Texte. Überall."
    });

    return (
        <Layout>
            <DefaultHead canonicalUrl={globalmeta.siteUrl + "/" + locale} title={"ReScribe - Home"}
                         description={"ReScribe - Schreibe bessere Texte. Überall."} locale={locale}
                         structuredData={structuredLd}/>
            <DefaultLayout>{page}</DefaultLayout>
        </Layout>
    );
};
